






















import { Component, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import OperationService from "@/services/OperationService";

@Component({
  components: {},
})
export default class BopOperationFamilyDropdownField extends BaseField {
  private isBeingEdited: Boolean = false;
  private selectedText: string = "";
  private init: boolean = true;
  private lvl1: any = null;
  // private selectedOperationFamily:string = "";
  private get operationFamilies(): Array<any> {
    return this.props.rowField.options;
  }
  private get displayData(): any {
    return table.findByPath(this.props.rowData, this.props.rowField.display);
  }

  private get valueField(): any {
    return this.props.rowField.valueField;
  }
  private lvl1Options(): Array<any> {
    return Object.values(this.lvl1.children);
  }
  private get textField(): any {
    return this.props.rowField.textField;
  }
  private get selectedValue(): string {
    try {
      let result = "";
      if (this.init && this.displayData) {
        result = this.displayData;
      } else {
        const options = this.lvl1Options();
        let arrResult = options.find(
          (d: any) => table.findByPath(d, this.valueField) === this.rawData
        );
        if (!arrResult) {
          arrResult = this.operationFamilies.find(
            (d: any) => table.findByPath(d, this.valueField) === this.rawData
          );
        }
        result =
          arrResult === undefined
            ? ""
            : table.findByPath(arrResult, this.textField);
      }
      return result;
    } catch {
      return "";
    }
  }
  private get computedClass() {
    let res = "";
    if (this.selectedValue && this.selectedValue.length > 100) res += " larger";
    return res;
  }
  private get selectedBopModel() {
    return this.$store.state.selectedBopModel;
  }
  get operationGroupId() {
    return this.props.rowField.operationGroupId;
  }
  // private get operationOptions() {
  //   return this.$store.state.bopOperationOptions;
  // }
  // private set operationOptions(bopOperationOptions: any) {
  //   const config = {
  //     operationGroupId: this.operationGroupId,
  //     bopOperationOptions,
  //   };
  //   this.$store.commit("changeBopOperationOptions", config);
  // }
  private get localOptions() {
    return this.props.rowData.localOptions;
  }
  private set localOptions(value) {
    this.props.rowData.localOptions = value;
  }
  /**
   * Methods
   */

  private async edit(newVal, eventType) {
    this.init = false;
    if (eventType === "change") {
      this.isBeingEdited = !this.isBeingEdited;
    }
    if (!this.props.rowField.isNotEditable && !this.isBeingEdited) {
      //remove isBeingEdited if dblclick
      if (eventType === "click") {
        this.isBeingEdited = !this.isBeingEdited;
        for (let opfKey in this.operationFamilies) {
          const opf = this.operationFamilies[opfKey];
          if (opf.id === this.rawData) {
            this.lvl1 = opf;
            break;
          }
          if (opf.children[this.rawData]) {
            this.lvl1 = opf;
            break;
          }
        }
      }
      if (newVal.length) {
        //store variable
        this.localOptions = (
          await OperationService.getBopOperations(
            this.selectedBopModel.id,
            1,
            this.$i18n.locale,
            null,
            null,
            newVal
          )
        ).data.map((el: any) => ({
          key: el,
          value: el.name,
        }));
      }
      if (!this.isBeingEdited) {
        (<any>this.$parent).reload();
      }
    }

    // this.$emit("dropDownChange", this.props);
  }

  /**
   * hooks
   */
  public created() {
    for (let opfKey in this.operationFamilies) {
      const opf = this.operationFamilies[opfKey];
      if (opf.id === this.rawData) {
        this.lvl1 = opf;
        break;
      }
      if (opf.children[this.rawData]) {
        this.lvl1 = opf;
        break;
      }
    }
  }
  @Watch("lvl1")
  private async onLvl1Changed() {
    if (!this.lvl1Options().length) {
      this.rawData = this.lvl1.id;
      await this.edit(this.lvl1.id, "change");
    }
  }
}
