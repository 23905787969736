






























import { Component, Prop, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import { BopModelEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";
import CaloTyuauterieHelper from "@/utils/CaloTyuauterieHelper";
import OperationExceptionService from "@/services/OperationExceptionService";
import { Guid } from "guid-typescript";

const math = require("mathjs");

@Component({
  components: {},
})
export default class BopQuantityField extends BaseField {
  @Prop({ required: false }) public areCoefficientsSelected!: boolean;
  @Prop({ required: false }) public isOperationPrincipal!: boolean;

  // @Prop({ required: true }) public props!: any;
  private isBeingEdited: Boolean = false;
  private previous: any = "";

  private width: number = 0;

  get isCalorifugeageAppareil(): boolean {
    return (
      this.$store.state.selectedBopModel &&
      this.$store.state.selectedBopModel.key ===
        BopModelEnum.CalorifugageAppareil
    );
  }
  private get hideUnit(): boolean {
    return this.props.rowField.hideUnit ?? false;
  }
  private get unit(): string {
    const unit =
      this.props.rowField.operationUnit ??
      this.props.rowData?.operation?.operationMesureUnitValue;
    return unit ?? "U";
  }

  private get specialClass(): string {
    if (
      this.props.rowField.property === "name" &&
      this.props.rowField.dataClass === "bop"
    ) {
      return "bopName";
    } else {
      return "";
    }
  }

  private get isCalorifugeageTyuauterie(): boolean {
    return (
      this.$store.state.selectedBopModel &&
      this.$store.state.selectedBopModel.key ===
        BopModelEnum.CalorifugageTuyauterie
    );
  }

  private get computedClass() {
    if (!this.props || !this.props.rowData || !this.props.rowField) {
      return "quantity disabled";
    }

    let res = "quantity ";
    if (!this.isReadOnly()) res += "field-pointer";
    else {
      res += " disabled";
    }
    if (this.rawData && this.rawData.length > 100) res += " larger";

    return res;
  }

  private get selectedGroupCoefficient() {
    return this.isCalorifugeageTyuauterie
      ? this.props.rowData.coefficientSelectedValues
      : null;
  }
  private get currentOperation() {
    if (
      !this.props?.rowData?.operations ||
      !this.props?.rowField?.operationKey
    ) {
      return null;
    }
    return this.props.rowData.operations[this.props.rowField.operationKey];
  }
  private isReadOnly() {
    return !(
      this.computedCanBeEdited &&
      (!this.isCalorifugeageTyuauterie ||
        !this.props.rowData.operations?.[this.props.rowField.operationKey]
          ?.isReadOnly)
    );
  }

  private get isDisabled(): boolean {
    return (
      (this.isCalorifugeageTyuauterie &&
        this.isOperationPrincipal &&
        !this.areCoefficientsSelected) ||
      this.props.rowField.disabled ||
      this.isReadOnly()
    );
  }

  private async getExceptionOperationCodes() {
    if (!this.isCalorifugeageTyuauterie) return;
    const epaisseurCal = this.selectedGroupCoefficient["EpaisseurColorifuge"]
      .value;
    const diametre = this.selectedGroupCoefficient["Diametre"]
      .coefficientOptionId;
    if (!epaisseurCal || !diametre) return;
    const result = (
      await OperationExceptionService.getOperationExceptionCodes(
        diametre,
        epaisseurCal
      )
    ).data;
    return result;
  }
  public edit(): void {
    if (this.isDisabled) return;
    //this.text = table.findByPath(this.props.rowData, this.props.rowField.property)
    if (!this.isReadOnly()) {
      this.isBeingEdited = !this.isBeingEdited;
      if (this.isBeingEdited) {
        this.previous = this.rawData;
        this.$nextTick(() => {
          (<HTMLInputElement>this.$refs.input).select();
          // (<HTMLElement>this.$refs.input).style.width = `${this.width}px`;
        });
      } else {
        if (this.isCalorifugeageTyuauterie) {
          this.computeOperationPoints();
        }
        (<any>this.$parent).reload();
      }
    }
  }

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }

  private onRawDataChange() {
    if (this.isDisabled) return;

    const isViroles =
      this.props.rowData.operation.operationFamilyKey === "viroles";
    this.setEditableState(!isViroles);

    if (!this.canBeEdited) {
      this.rawData = 0;
    }
  }
  private computeOperationPoints() {
    if (this.isDisabled) return;
    CaloTyuauterieHelper.computeOperationPoints(
      this.currentOperation,
      this.props.rowData
    );
  }
  private created() {
    if (this.isDisabled) return;
    if (
      this.isCalorifugeageAppareil &&
      this.props.rowField.property === "quantity"
    ) {
      this.onRawDataChange();
      this.$watch("rawData", this.onRawDataChange, { deep: true });
    } else if (this.isCalorifugeageTyuauterie) {
      this.computeOperationPoints();
      this.$watch(
        "selectedGroupCoefficient",
        this.onCurrentOperationCoefficientChange,
        { deep: true }
      );
      this.$watch(
        "currentOperation.isReadOnly",
        this.onCurrentOperationIsReadOnlyChange,
        { deep: true }
      );
    }
    (<any>this.$parent).reload();
  }
  private onCurrentOperationIsReadOnlyChange() {
    if (this.isDisabled) return;

    // Check if currentOperation is available before accessing isReadOnly
    if (this.currentOperation && this.currentOperation.isReadOnly) {
      this.props.rowData.fieldKey = Guid.create().toString();
    }
  }
  private mounted() {
    if (this.isDisabled) return;
    this.width = (<HTMLElement>this.$refs.rawField).offsetWidth;
  }

  private async onCurrentOperationCoefficientChange() {
    if (this.isDisabled) return;
    await this.$nextTick();

    if (this.props.rowData.isSelectedGroupCoefficientsProcessed) return;
    this.props.rowData.isSelectedGroupCoefficientsProcessed = true;

    const exceptionCodes = await this.getExceptionOperationCodes();

    // first validation step of diametre and epaisseur
    if (!exceptionCodes) {
      return;
    }

    // reset all operation to initial state
    for (let i in this.props.rowData.operations) {
      this.props.rowData.operations[i].isReadOnly = false;
    }
    // lock the exception operations and set their quantity to 0
    exceptionCodes.forEach((code) => {
      this.props.rowData.operations[code].isReadOnly = true;
      this.props.rowData.operations[code].quantity = 0;
      this.props.rowData.operations[code].totalPoints = 0;
    });
    //second validation step
    if (this.selectedGroupCoefficient["K2"].coefficientOptionId) {
      CaloTyuauterieHelper.computeOperationPoints(
        this.props.rowData.operations[this.props.rowField.operationKey],
        this.props.rowData
      );
    }
    (<any>this.$parent).reload();
  }
  /**
   * ######
   * Watchers
   * ######
   */

  @Watch("props.rowData.operation.operationFamilyKey")
  private onOperationFamilyKeyChange(newValue: string) {
    const isViroles = newValue === "viroles";
    this.setEditableState(!isViroles);

    if (!this.canBeEdited) {
      this.rawData = 0;
    }
  }
}
