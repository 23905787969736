


































import { Component, Prop, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import { BopModelEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";

const DISABLING_DIAMETRE_OPERATION_FAMILIES = [
  "nombreDeDecoupes",
  "semiDemontables",
  "piecesDeForme",
  "demontables",
];
const DISABLING_LONGUEUR_OPERATION_FAMILIES = [
  "nombreDeDecoupes",
  "fondsBombesOuConiques",
  "semiDemontables",
  "piecesDeForme",
  "demontables",
];
@Component({
  components: {},
})
export default class CoefficientEditField extends BaseField {
  @Prop({ required: false }) public areCoefficientsSelected!: boolean;

  private isBeingEdited: Boolean = false;
  private previous: any = "";

  private width: number = 0;

  // private IDSAPRegex
  get isCalorifugeageAppareil(): boolean {
    return (
      this.$store.state.selectedBopModel.key ===
      BopModelEnum.CalorifugageAppareil
    );
  }

  private get displayedValue(): string {
    return this.rawData === Constants.DEFAULT_VALUE_EMPTY_OPTIONS ||
      this.rawData === undefined ||
      this.rawData === null ||
      this.rawData === ""
      ? this.$t("(empty)").toString()
      : this.rawData.toString();
  }

  private set displayedValue(newValue: string) {
    this.rawData =
      newValue === this.$t("(empty)").toString() || newValue === ""
        ? Constants.DEFAULT_VALUE_EMPTY_OPTIONS
        : newValue;
  }

  private get specialClass(): string {
    if (
      this.props.rowField.property === "name" &&
      this.props.rowField.dataClass === "bop"
    ) {
      return "bopName";
    } else {
      return "";
    }
  }

  private get operationFamilyKey() {
    return this.props.rowData.operation?.operationFamilyKey;
  }
  private get operationId() {
    return this.props.rowData.operation?.id;
  }

  private get coefficientId(): string {
    return this.props.rowField.coefficient.id;
  }

  private get coefficientKey(): string {
    return this.props.rowField.coefficient.key;
  }

  private get dataContainer(): string {
    return this.props.rowField.dataContainer;
  }
  private get isCalorifugeageTyuauterie(): boolean {
    return (
      this.$store.state.selectedBopModel?.key ===
      BopModelEnum.CalorifugageTuyauterie
    );
  }
  public edit(): void {
    if (this.computedCanBeEdited) {
      const container = <HTMLElement>this.$refs.container;
      const input = <HTMLElement>this.$refs.input;

      const height = container.offsetHeight;
      const width = container.offsetWidth;
      const paddingLeft = parseInt(
        getComputedStyle(container).paddingLeft || "0"
      );
      const paddingRight = parseInt(
        getComputedStyle(container).paddingRight || "0"
      );

      input.style.width = `${width - paddingLeft - paddingRight}px`;
      input.style.height = `${height}px`;

      this.isBeingEdited = !this.isBeingEdited;

      if (this.isBeingEdited) {
        this.previous = this.rawData;

        this.$nextTick(() => {
          (<HTMLInputElement>input).select();
        });
      } else {
        this.close();
      }
    }
  }

  public close() {
    this.isBeingEdited = false;
    this.$nextTick().then(() => {
      if (this.isCalorifugeageTyuauterie) {
        this.props.rowData.isSelectedGroupCoefficientsProcessed = false;
      }
      table.setByPath(
        this.props.rowData,
        this.dataContainer,
        this.rawData === Constants.DEFAULT_VALUE_EMPTY_OPTIONS
          ? 0
          : this.rawData
      );
    });
    (<any>this.$parent).reload();
  }

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }

  private created() {
    if (this.rawData) {
      table.setByPath(this.props.rowData, this.dataContainer, this.rawData);
    }
    if (this.isCalorifugeageAppareil) this.onOperationFamilyKeyChange();
  }

  private mounted() {
    (<any>this.$parent).reload();
  }

  @Watch("operationId")
  private onOperationIdChange() {
    this.onOperationFamilyKeyChange();
  }

  @Watch("operationFamilyKey")
  private onOperationFamilyKeyChange() {
    if (!this.isCalorifugeageAppareil) return;

    if (this.coefficientKey) {
      if (
        this.coefficientKey === "diametre" &&
        DISABLING_DIAMETRE_OPERATION_FAMILIES.includes(this.operationFamilyKey)
      ) {
        this.setEditableState(false);
        this.rawData = Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
      } else if (
        this.coefficientKey === "longueur" &&
        DISABLING_LONGUEUR_OPERATION_FAMILIES.includes(this.operationFamilyKey)
      ) {
        this.rawData = Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
        this.setEditableState(false);
      } else {
        this.setEditableState(true);
      }
    }
  }

  // @Watch("rawData")
  // onRawDataChange() {
  //   table.setByPath(this.props.rowData, this.dataContainer, this.rawData);
  //   (<any>this.$parent).reload();
  // }
}
